<template>
  <div class="vs-row">
    <vx-card actionable class="cardx" title="Warehouse Dispatches">
      <vs-table :data="warehouseData">
        <template slot="thead" class="exc">
          <vs-th>Can-ID</vs-th>
          <vs-th>Person Name</vs-th>
          <vs-th>Course</vs-th>
          <vs-th>Courier Name</vs-th>
          <vs-th>Courier Number</vs-th>
          <vs-th>Books Sent</vs-th>
          <vs-th>Action</vs-th>
        </template>

        <template>
          <vs-tr
            :data="tr"
            :key="indextr"
            v-for="(tr, indextr) in warehouseData"
          >
            <vs-td>{{ tr.identity }}</vs-td>
            <vs-td>{{ tr.person_name }}</vs-td>
            <vs-td>{{ tr.name }}</vs-td>
            <vs-td>{{ tr.purchase_deliverables[0].courier_company }}</vs-td>
            <vs-td>{{ tr.purchase_deliverables[0].courier_number }}</vs-td>
            <vs-td>{{ getBooks(tr.purchase_deliverables) }}</vs-td>
            <vs-td
              ><vs-row>
                <vs-button
                  size="small"
                  color="primary"
                  @click="CourierNumberUpdated(tr)"
                  >Update Courier Number</vs-button
                ></vs-row
              ></vs-td
            >
          </vs-tr>
        </template>
      </vs-table>

      <vs-pagination
        style="margin-top: 3%"
        :total="totalPages"
        v-model="currentPage"
      ></vs-pagination>
    </vx-card>
  </div>
</template>

<script>
import EventBus from "../components/eventbus.js";
import axios from "axios";
import constants from "../../constants.json";
export default {
  data() {
    return {
      warehouseData: [],
      currentPage: 1,
      totalPages: 1,
    };
  },
  watch: {
    currentPage() {
      this.fetchWarehouseData();
    },
  },
  mounted() {
    EventBus.$on("reloadWarehouseDispatches", () => {
      this.fetchWarehouseData();
    });
    this.fetchWarehouseData();
  },
  methods: {
    fetchWarehouseData() {
      let params = {
        page: this.currentPage,
      };
      let url = `${constants.SERVER_API}getdispatchedWarehouseData`;
      axios
        .get(url, {
          params: params,
          headers: { Authorization: `Bearer ${localStorage.userAccessToken}` },
        })
        .then((response) => {
          console.log(response);
          if (response.data.status === "error") {
            this.handleNotification(response);
            return;
          }
          this.warehouseData = response.data.data.data;
          this.totalPages = response.data.data.last_page;
          this.currentPage = response.data.data.current_page;
        })
        .catch((error) => {
          this.handleError(error);
        });
    },
    CourierNumberUpdated(tr) {
      EventBus.$emit("open-couriernumberPopup", tr);
    },
    getBooks(deliverables) {
      let books = [];
      deliverables.forEach((deliverable) => {
        books.push(deliverable.name);
      });
      books = books.join();
      return books;
    },
  },
};
</script>
